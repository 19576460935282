import { Link } from "gatsby"
import React from "react"
import Layout from "../components/layouts/Layout/layout"
import "./404.scss"

const NotFoundPage = () => (
  <Layout>
    <div className="not-found">
      <h1>404 : Page non trouvée</h1>
      <p>Oups, la page que vous cherchez n'existe plus à cette adresse.</p>
      <Link
        to="/"
      >
        Retour vers la page d'accueil.
      </Link>
    </div>
  </Layout>
)

export default NotFoundPage
